.App {
  position: relative;
}

.App-header {
  background-color: #1f1d3a;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.card {
  position: relative;
  width: 520px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 5px 44px rgba(0, 0, 0, 0.4);
  z-index: 1;
  color: #1e1e37;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
}

@media (max-width: 800px) {
  .card {
    transform: scale(0.8);
  }
}
@media (max-width: 550px) {
  .card {
    transform: scale(0.7);
  }
}
@media (max-width: 480px) {
  .card {
    transform: scale(0.6);
  }
}
@media (max-width: 400px) {
  .card {
    transform: scale(0.5);
  }
}
@media (max-width: 350px) {
  .card {
    transform: scale(0.45);
  }
}

.background-pattern,
.background-pattern-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.background-pattern {
  opacity: 0.4;
  background: url("img/background-pattern.svg");
}
.background-pattern-overlay {
  background: linear-gradient(
    40deg,
    rgba(30, 30, 56, 0) 0%,
    rgba(30, 30, 56, 1) 49%
  );
}

.card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.card-logo {
  background: url("img/logo.svg");
  width: 46px;
  height: 46px;
}
.card-logo-outlined-1,
.card-logo-outlined-2 {
  position: absolute;
  top: 0;
  opacity: 0.3;
}
.card-logo-outlined-1 {
  width: 319px;
  height: 330px;
  right: 0;
  background: url("img/logo-outlined-1.svg");
}
.card-logo-outlined-2 {
  width: 620px;
  height: 350px;
  left: 0;
  background: url("img/logo-outlined-2.svg");
}
.card-title {
  font-size: 45px;
  margin-bottom: 10px;
  font-weight: 500;
}
.card-subtitles {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.7;
}
.card-contacts {
  display: flex;
  flex-direction: column;
  text-align: right;
  text-transform: none;
  margin-top: -30px;
}
.card-contacts > div + div {
  margin-top: 10px;
}

.footer {
  position: absolute;
  bottom: 8px;
  display: flex;
  justify-content: center;
  opacity: 0.6;
  font-size: 13px;
  color: white;
}

.footer > a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.modal-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  width: 800px;
  padding: 30px;
  max-height: 80vh;
  max-width: 80vw;
  margin: 0 20px;
  position: fixed;
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-text {
  font-size: 14px;
  color: black;
  max-height: 100%;
}

.modal-text > h1 {
  margin-top: -16px;
}

.icon {
  width: 20px;
  height: 20px;
  background: url("img/icons/close.svg");
}

.clickable {
  cursor: pointer;
}
